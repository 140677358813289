@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
    display: none;
}

body {
    scroll-behavior: smooth;
}