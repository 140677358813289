@font-face {
    font-family: 'Integral';
    src:  url('../src/FONTS/IntegralCF-Bold.woff2') format('woff2'),
          url('../src/FONTS/IntegralCF-Bold.woff') format('woff');
  }
body{
    font-family: 'Raleway', sans-serif;
}
h1{
    font-family: 'Integral', sans-serif;
}